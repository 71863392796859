.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header div{
  width: 40vw;
  display: flex;
  margin-top: 2rem;
}

.App-header div input{
  width: 90%;
  height: 2rem;
  font-size: 1.2rem;
}

.App-header div button{
  width: 10%;
  font-size: .9rem;
}

.content-info-messsages{
  display: flex;
  flex-direction: column;
  height: 50vh;
  overflow-y: auto;
}

@media(max-width:768px){
  .App-header div{
    width: 90vw;
  }

  .App-header div input{
    width: 80%;
    height: 2rem;
    font-size: 1.2rem;
  }
  
  .App-header div button{
    width: 18%;
    font-size: .9rem;
  }
}